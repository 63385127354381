import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Layout, Form, Steps, message, Modal } from 'antd';
import AppHeader from '../components/Header/AppHeader';
import SideMenu from '../components/SideMenu/SideMenu';
import FooterComponent from '../components/Footer/FooterComponent';
import MapButton from '../components/MapComponent/MapButton';
import UsinaSolarForm from '../components/Cadastro/UsinaSolar';
import Site from '../components/Cadastro/Site';
import Bateria from '../components/Cadastro/Bateria';
import UsinaEolica from '../components/Cadastro/UsinaEolica';
import UsinaTermeletrica from '../components/Cadastro/UsinaTermeletrica';
import GridComponent from '../components/Cadastro/Grid';
import Carregador from '../components/Cadastro/Carregador';
import { FormProvider } from '../context/FormProvider';
import { v4 as uuidv4 } from 'uuid';

const url_base = process.env.REACT_APP_URL_BACKEND;
const apiKey =  process.env.REACT_APP_API_KEY_MAPS;

const { Content } = Layout;
const { Step } = Steps;

const CadastrosPage = () => {
    const [currentStep, setCurrentStep] = useState(0);
    const [siteSteps, setSiteSteps] = useState([]);
    const [stepUuid, setStepUuid] = useState({});
    const [form] = Form.useForm();
    const [cepData, setCepData] = useState({ rua: 'Digite o CEP', bairro: 'Digite o número', cidade: '', numero: '' });
    const [logradouroNumero, setLogradouro] = useState('');
    const [googleMapsUrl, setGoogleMapsUrl] = useState('');
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [sitesExistentes, setSitesExistentes] = useState([]); 
    const [selectedSite, setSelectedSite] = useState(''); 
    const [useSameAddress, setUseSameAddress] = useState(true);
    const [siteData, setSiteData] = useState(null);

    const token = localStorage.getItem('token');

    const handleSiteSave = (siteInfo) => {
        setSiteData(siteInfo); 
        setCurrentStep(currentStep + 1); 
    };

    useEffect(()  => {
        fetchSitesExistentes().then((newSitesExistentes) => {
            setSitesExistentes(newSitesExistentes);
        })
    }, []);

    useEffect(() => {
        if (siteSteps.length > 0) {
            const initialStepUuids = {};
            siteSteps.forEach((_, index) => {
                initialStepUuids[index] = uuidv4(); 
            });
            setStepUuid(initialStepUuids);
        }
    }, [siteSteps]);
    
    useEffect(()  => { 
        if (sitesExistentes === null || sitesExistentes.length === 0) return;           
        setSiteSteps([
            { title: 'Site e Endereço do Site', content: 

                <Site
                    form={form}
                    sitesExistentes={sitesExistentes}
                    selectedSite={selectedSite}
                    handleSiteChange={handleSiteChange}
                    handleCepChange={handleCepChange}
                    handleNumeroChange={handleNumeroChange}
                    currentStep={currentStep}
                    totalSteps={siteSteps.length}
                    onPrev={() => setCurrentStep(currentStep - 1)}
                    onNext={() => setCurrentStep(currentStep + 1)}
                    apiKey={apiKey}
                    token={token}
                    onSave={handleSiteSave}
                />

            }, 
            { title: 'Usina Solar', content:
                <FormProvider>
                    <UsinaSolarForm
                        form={form}
                        siteData={siteData}
                        currentStep={currentStep}
                        totalSteps={siteSteps.length}
                        onPrev={() => setCurrentStep(currentStep - 1)}
                        onNext={() => setCurrentStep(currentStep + 1)}
                        handleCepChange={handleCepChange}
                    />
                </FormProvider>
                

            }, 
            { title: 'Usina Eólica', content: <UsinaEolica form={form} onSave={() => form.getFieldsValue()} /> }, 
            { title: 'Usina Termelétrica', content: <UsinaTermeletrica form={form} onSave={() => form.getFieldsValue()} /> }, 
            { title: 'Bateria', content: <Bateria
                form={form}
                useSameAddress={useSameAddress}
                setUseSameAddress={setUseSameAddress}
                handleCepChange={handleCepChange}
                handleNumeroChange={handleNumeroChange} 
                onSave={() => form.getFieldsValue()} 
            /> },
            { title: 'Grid', content: <GridComponent form={form} onSave={() => form.getFieldsValue()} /> }, 
            { title: 'Carregador', content: <Carregador form={form} onSave={() => form.getFieldsValue()}  /> } 
        ]);
        
    }, [sitesExistentes,selectedSite,siteData]);
    
    
    const fetchSitesExistentes = async () => {
        try {
            
            const response = await axios.get(`${url_base}/api/sites`, {
                headers: { Authorization: `Bearer ${token}` }
            });
            message.success('Sucesso ao carregar os sites existentes.');
            return response.data;
        } catch (error) {
            message.error('Erro ao carregar sites existentes.');
        }
    };

    // useEffect(() => {
    //         setGoogleMapsUrl(generateGoogleMapsUrl(
    //             logradouroNumero,
    //             cepData.rua,
    //             cepData.bairro,
    //             cepData.cidade
    //         ));
    // }, [cepData]);

    // useEffect(() => {
    //     setGoogleMapsUrl(generateGoogleMapsUrl(logradouroNumero, cepData.rua, cepData.bairro, cepData.cidade));
    // }, [logradouroNumero]);

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleCloseModal = () => {
        setIsModalVisible(false);
    };
    
    // Gera a URL do Google Maps para exibição no iframe
    const generateGoogleMapsUrl = (lat,long) => {
        const url = `https://www.google.com/maps/embed/v1/place?key=${apiKey}&q=${lat},${long}&maptype=satellite&zoom=18`;
        return url;
    };
    
    const handleNumeroChange = (value) => {
        setLogradouro(value);
    };

    const handleCepChange = async (value) => {
        const cep = value.replace('-', '');
        if (cep.length > 7) {
            try {
                const response = await axios.get(`https://viacep.com.br/ws/${cep}/json/`);
                // if (response.data && response.data.logradouro && response.data.bairro && response.data.localidade ) {
                if (response.data && !response.data.erro ) {
                    const newCepData = {
                        rua: response.data.logradouro,
                        bairro: response.data.bairro,
                        cidade: response.data.localidade,
                        numero: logradouroNumero
                    };
                    setCepData(newCepData);
                    form.setFieldsValue({
                        rua: response.data.logradouro,
                        bairro: response.data.bairro,
                        cidade: response.data.localidade,
                    });
                    message.success('Endereço encontrado!');
                } else {
                    // const newCepData = {
                    //     rua: siteData?.logradouro,
                    //     bairro: siteData?.bairro,
                    //     cidade: siteData?.localidade,
                    //     numero: logradouroNumero
                    // };
                    // setCepData(newCepData);
                    // form.setFieldsValue({
                    //     rua: siteData?.logradouro,
                    //     bairro: siteData?.bairro,
                    //     cidade: siteData?.localidade,
                    //     // numero: siteData?.numero
                    // });
                    // message.success('Dados recuperados!');
                    // console.log(siteData);
                    message.error('CEP não encontrado.');
                }
            } catch (error) {
                message.error('Erro ao buscar o CEP.');
            }
        }
    };
    
    
    // const handlerConclusion = async () => {
    //     try{
    //         await form.validateFields()
    //         message.success('Cadastro concluído!')
    //     }catch{
    //         message.error('Preencha todos os campos!')
            
    //     };
    // };

    const handleSiteChange = async (value) => {       
        setSelectedSite(value);
        const site = sitesExistentes.find(site => site.id === value);
        setSiteData(site);
        if (site) {
            form.setFieldsValue({
                nome: site.nome,
                responsavel: site.pessoaResponsavel,
                telefone: site.telefone,
                email: site.emailResponsavel,
                cargo: site.cargo,
                cep: site.cep,
                rua: site.logradouro,
                bairro: site.bairro,
                complemento: site.complemento,
                numero: site.numero,
                cidade: site.cidade
            });
            // updateFormData("siteAddress", {
            //     cep: site.cep,
            //     rua: site.rua,
            //     bairro: site.bairro,
            //     complemento: site.complemento,
            //     numero: site.numero,
            //     cidade: site.cidade
            // });
            const data = {
                rua: site.rua,
                bairro: site.bairro,
                cidade: site.cidade,
                numero: site.logradouro,
            }
            setCepData(data);
            // handleCepChange(site.cep); // Passa o valor do CEP
            // handleNumeroChange(site.numero); // Passa o número
            setGoogleMapsUrl(generateGoogleMapsUrl(site.latitude,site.longitude));
        } else {
            form.resetFields();
            setCepData({ rua: '', bairro: '', cidade: '', numero: '' });
        }
    };
    



    return (
        <Layout style={{ height: '100vh' }}>
            <SideMenu style={{ position: 'fixed', zIndex: 1 }} />
            <Layout>
                <AppHeader style={{ position: 'fixed', width: '100%', zIndex: 1, top: 0 }} />
                <Content style={{ overflowY: 'auto', padding: '20px', display: 'flex', flexDirection: 'column', flex: 1 }}>
                    <div style={{ flex: 1 }}>
                        <h2>Cadastro de Site</h2>
                        <Steps current={currentStep} onChange={setCurrentStep} style={{ marginBottom: '20px' }}>
                            {siteSteps.map((step, index) => (
                                <Step key={index} title={step.title} />
                            ))}
                        </Steps>

                        <div style={{ backgroundColor:'#FFF', padding: '30px', borderRadius: '8px', marginTop: '50px' }}>
                            <div style={{ marginBottom: '20px' }}>{siteSteps[currentStep]?.content}</div>

                            <div style={{ marginTop: '20px', paddingLeft: '20px' }}>
                                <MapButton
                                    rua={cepData.rua}
                                    bairro={cepData.bairro}
                                    cidade={cepData.cidade}
                                    numero={logradouroNumero}
                                    style={{ width: '40%' }}
                                    onClick={showModal}
                                />
                            </div>
                        </div>
                    </div>

                    <Modal open={isModalVisible} onCancel={handleCloseModal} footer={null} width={800}>
                        {googleMapsUrl ? (
                            <iframe src={googleMapsUrl} title="Mapa do Google" width="100%" height="550" style={{ border: 0 }} allowFullScreen loading="lazy" />
                        ) : (
                            <p>Carregando mapa...</p>
                        )}
                    </Modal>

                    <FooterComponent />
                </Content>
            </Layout>
        </Layout>
    );
};

export default CadastrosPage;