import React, { useEffect, useState } from 'react';
import { Layout, Table, Select, Typography, message, Tag, Modal, Button } from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';
import axios from 'axios';
import AppHeader from '../components/Header/AppHeader';
import SideMenu from '../components/SideMenu/SideMenu';
import FooterComponent from '../components/Footer/FooterComponent';
import { useAuth } from '../hooks/useAuth';
const url_base = process.env.REACT_APP_URL_BACKEND;

const { Content } = Layout;
const { Title } = Typography;
const { Option } = Select;

const UserManagementPage = () => {
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);

    const { user } = useAuth();
    const currentUser = user?.username; 


    const token = localStorage.getItem('token');

    useEffect(() => {
        const fetchUsers = async () => {
            setLoading(true);
            try {
                const response = await axios.get(`${url_base}/api/admin/users?page=0&size=20&sort=id,asc`, {
                    headers: { Authorization: `Bearer ${token}` },
                });
                setUsers(response.data);
            } catch (error) {
                message.error('Erro ao carregar a lista de usuários.');
            } finally {
                setLoading(false);
            }
        };

        fetchUsers();
    }, [token]);


    const showModal = (user) => {
        setSelectedUser(user);
        setIsModalVisible(true);
    };
    
    const handleModalClose = () => {
        setIsModalVisible(false);
        setSelectedUser(null);
    };
    
    const handleSaveRole = async (newRole) => {
        if (!selectedUser) return;
    
        if (selectedUser.login === currentUser) {
            message.error('Você não pode alterar sua própria função.');
            return;
        }
    
        if (selectedUser.authorities.includes('ROLE_ADMIN')) {
            message.error('Você não pode alterar a função de um administrador.');
            return;
        }
    
        try {
            await handleRoleChange(selectedUser.username, newRole);
            setIsModalVisible(false);
            message.success('Função do usuário atualizada com sucesso!');
        } catch (error) {
            message.error('Erro ao atualizar a função do usuário.');
        }
    };
    
    
    const handleRoleChange = async (username, newRole) => {
        if (!selectedUser) return;
    
        try {
            const updatedAuthorities = [newRole];
    
            await axios.put(
                `${url_base}/api/admin/users`, 
                { ...selectedUser, authorities: updatedAuthorities }, 
                {
                    headers: { Authorization: `Bearer ${token}` },
                }
            );
    
            setUsers((prevUsers) =>
                prevUsers.map((user) =>
                    user.email === selectedUser.email ? { ...user, authorities: updatedAuthorities } : user
                )
            );
    
        } catch (error) {
            message.error('Erro ao atualizar a função do usuário.');
            console.error('Erro ao atualizar a função:', error);
        }
    };
    


    const toggleStatus = async (record) => {
        if (record.login === currentUser) {
            message.error('Você não pode alterar seu próprio status.');
            return;
        }
    
        if (record.authorities.includes('ROLE_ADMIN')) {
            message.error('Você não pode alterar o status de um administrador.');
            return;
        }
    
        try {
            const updatedStatus = !record.activated;
    
            await axios.put(
                `${url_base}/api/admin/users`, 
                { ...record, activated: updatedStatus }, 
                {
                    headers: { Authorization: `Bearer ${token}` },
                }
            );
    
            setUsers((prevUsers) =>
                prevUsers.map((user) =>
                    user.lastName === record.lastName ? { ...user, activated: updatedStatus } : user
                )
            );
    
            message.success(`Status atualizado para ${updatedStatus ? 'Ativo' : 'Inativo'}`);
        } catch (error) {
            message.error('Erro ao atualizar o status do usuário.');
            console.error('Erro ao atualizar o status:', error);
        }
    };
    
    

    const columns = [
        {
            title: 'Código',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Login',
            dataIndex: 'login',
            key: 'login',
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Status',
            dataIndex: 'activated',
            key: 'activated',
            render: (stts, record) => (
                <Tag
                    color={stts ? 'green' : 'red'}
                    key={stts}
                    style={{ cursor: 'pointer' }}
                    onClick={() => toggleStatus(record)}
                    disabled={record.login === currentUser || record.authorities.includes('ROLE_ADMIN')}
                >
                    {stts ? 'Ativo' : 'Inativo'}
                </Tag>
            ),
        },
        
        {
            title: 'Última Modificação',
            dataIndex: 'lastModifiedDate',
            key: 'lastModifiedDate',
        },
        {
            title: 'Funções',
            dataIndex: 'authorities',
            key: 'authorities',
            render: (roles, record) => (
                <div>
                    {roles.map((role) => (
                        <Tag color={getRoleColor(role)} key={role}>
                            {formatRole(role)}
                        </Tag>
                    ))}
                    
                </div>
            ),
        },
        {
            title: 'Ações',
            dataIndex: 'acoes',
            key: 'acoes',
            render: (_, record) => (
                <Button
                    icon={<EllipsisOutlined />}
                    onClick={() => showModal(record)}
                    type="text"
                    disabled={record.login === currentUser || record.authorities.includes('ROLE_ADMIN')}
                />
            ),
        }
        
    ];

    const getRoleColor = (role) => {
        switch (role) {
            case 'ROLE_ADMIN':
                return 'red';
            case 'ROLE_DEV':
                return 'blue';
            case 'ROLE_USER':
                return 'green';
            default:
                return 'gray';
        }
    };

    const formatRole = (role) => {
        switch (role) {
            case 'ROLE_ADMIN':
                return 'Administrador';
            case 'ROLE_DEV':
                return 'Desenvolvedor';
            case 'ROLE_USER':
                return 'Usuário';
            default:
                return role;
        }
    };

    return (
        <Layout style={{ height: '100vh' }}>
            <SideMenu style={{ position: 'fixed', zIndex: 1 }} />
            <Layout>
                <AppHeader style={{ position: 'fixed', width: '100%', zIndex: 1, top: 0 }} />
                <Content style={{ overflowY: 'auto', padding: '20px', display: 'flex', flexDirection: 'column', flex: 1 }}>
                    <div style={{ flex: 1 }}>
                        <Title level={2}>Gerenciamento de Usuários</Title>
                        <Table
                            columns={columns}
                            dataSource={users}
                            rowKey="username"
                            loading={loading}
                            pagination={{ pageSize: 10 }}
                        />
                    </div>
                    <FooterComponent />
                </Content>
            </Layout>
            <Modal
                title="Editar Função do Usuário"
                visible={isModalVisible}
                onCancel={handleModalClose}
                onOk={() => handleSaveRole(selectedUser.authorities[0])}
            >
                <Select
                    defaultValue={selectedUser?.authorities[0]}
                    style={{ width: '100%' }}
                    onChange={(value) => handleSaveRole(value)}
                >
                    <Option value="ROLE_ADMIN">Administrador</Option>
                    <Option value="ROLE_DEV">Desenvolvedor</Option>
                    <Option value="ROLE_USER">Usuário</Option>
                </Select>
            </Modal>

        </Layout>
        
    );
};

export default UserManagementPage;
