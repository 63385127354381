import React, { useEffect, useState } from "react";
import { Card, Row, Col, Spin, Input } from "antd";
import axios from "axios";

const { Search } = Input;

const GalleryComponent = () => {
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [searchText, setSearchText] = useState("");

    // Função para buscar os dados do endpoint
    const fetchGalleryData = async () => {
        setLoading(true);
        try {
            const response = await axios.get("http://localhost:4000/combined-data");
            if (response.data.success) {
                const processedData = response.data.data.map((item) => ({
                    DscMuninicpios: item.DscMuninicpios,
                    NumCoordNEmpreendimento: item.NumCoordNEmpreendimento,
                    NumCoordEEmpreendimento: item.NumCoordEEmpreendimento,
                    DatEntradaOperacao: item.DatEntradaOperacao,
                    MdaPotenciaOutorgadaKw: item.MdaPotenciaOutorgadaKw,
                    MdaPotenciaFiscalizadaKw: item.MdaPotenciaFiscalizadaKw,
                    MdaGarantiaFisicaKw: item.MdaGarantiaFisicaKw,
                    SigTipoGeracao: item.SigTipoGeracao,
                    CodCEG: item.CodCEG,
                    NomAgente: item.NomAgente,
                }));
                setData(processedData);
                setFilteredData(processedData);
            }
        } catch (error) {
            console.error("Erro ao buscar os dados:", error);
        }
        setLoading(false);
    };

    // Hook para buscar os dados ao montar o componente
    useEffect(() => {
        fetchGalleryData();
    }, []);

    // Função para filtrar os dados
    const handleSearch = (value) => {
        setSearchText(value);
        if (!value) {
            setFilteredData(data);
            return;
        }
        const lowercasedValue = value.toLowerCase();
        const filtered = data.filter((item) =>
            Object.values(item).some((field) =>
                String(field).toLowerCase().includes(lowercasedValue)
            )
        );
        setFilteredData(filtered);
    };

    return (
        <Spin spinning={loading}>
            <div style={{ marginBottom: "16px" }}>
                <Search
                    placeholder="Filtrar por qualquer campo..."
                    allowClear
                    value={searchText}
                    onChange={(e) => handleSearch(e.target.value)}
                    style={{ width: "300px" }}
                />
            </div>
            <Row gutter={[16, 16]}>
                {filteredData.map((item, index) => (
                    <Col key={index} xs={24} sm={12} md={8} lg={6}>
                        <Card
                            title={item.DscMuninicpios}
                            bordered
                            hoverable
                        >
                            <p><b>Coordenadas:</b> {item.NumCoordNEmpreendimento}, {item.NumCoordEEmpreendimento}</p>
                            <p><b>Entrada em Operação:</b> {item.DatEntradaOperacao}</p>
                            <p><b>Potência Outorgada (kW):</b> {item.MdaPotenciaOutorgadaKw}</p>
                            <p><b>Potência Fiscalizada (kW):</b> {item.MdaPotenciaFiscalizadaKw}</p>
                            <p><b>Garantia Física (kW):</b> {item.MdaGarantiaFisicaKw}</p>
                            <p><b>Tipo de Geração:</b> {item.SigTipoGeracao}</p>
                            <p><b>Código CEG:</b> {item.CodCEG}</p>
                            <p><b>Nome do Agente:</b> {item.NomAgente}</p>
                        </Card>
                    </Col>
                ))}
            </Row>
        </Spin>
    );
};

export default GalleryComponent;
