import React, { useState } from 'react';
import { Layout, Form, Input, Select, Row, Col, Button, message } from 'antd';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

const { Option } = Select;

const Site = ({
    form,
    sitesExistentes,
    selectedSite,
    handleSiteChange,
    handleCepChange,
    handleNumeroChange,
    currentStep,
    totalSteps,
    onPrev,
    onNext,
    onSave,
    token,
}) => {
    const [loading, setLoading] = useState(false);

    const handleSave = async () => {
        try {
            setLoading(true);
            await form.validateFields();
            const values = form.getFieldsValue();

            const payload = {
                uuid: uuidv4(),
                nome: values.nome,
                pessoaResponsavel: values.responsavel,
                celular: values.telefone,
                cargo: values.cargo,
                cep: values.cep,
                emailResponsavel: values.email,
                numero: parseInt(values.numero, 10),
                logradouro: values.rua,
                bairro: values.bairro,
                cidade: values.cidade,
                estado: values.estado,
                latitude: 0,
                longitude: 0,
                telefone: values.telefone,
                status: values.status,
                metadata: JSON.stringify({}),

            };

            await axios.post(`${process.env.REACT_APP_URL_BACKEND}/api/sites`, payload, {
                headers: { Authorization: `Bearer ${token}` },
            });

            message.success('Site salvo com sucesso!');
            onSave(payload);
        } catch (error) {
            message.error(error.message || 'Erro ao salvar o site.');
        } finally {
            setLoading(false);
        }
    };
    return (
        <Layout style={{ padding: '20px', backgroundColor: 'white' }}>
            <Form layout="vertical" form={form}>
                <h2 style={{ color: "#2957A4" }}>Site</h2>

                <Row gutter={12} align="bottom">
                    <Col span={8}>
                        <Form.Item label="Selecione Site Existente">
                            <Select
                                placeholder="Escolha um site existente"
                                value={selectedSite || " "}
                                onChange={handleSiteChange}
                                allowClear
                            >
                                {sitesExistentes.map(site => (
                                    <Option key={site.id} value={site.id}>
                                        {site.nome}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={12} align="bottom">
                    <Col span={4}>
                        <Form.Item
                            label="Nome do Site"
                            name="nome"
                            rules={[{ required: true, message: 'Informe o nome do projeto!' }]}
                        >
                            <Input placeholder="Nome do Projeto" />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item
                            label="Pessoa Responsável"
                            name="responsavel"
                            rules={[{ required: true, message: 'Informe o responsável!' }]}
                        >
                            <Input placeholder="Digite o Nome" />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item
                            label="Telefone ou Celular"
                            name="telefone"
                            rules={[{ required: true, message: 'Informe o telefone!' }]}
                        >
                            <Input placeholder="Digite o Número" />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item
                            label="Email"
                            name="email"
                            rules={[{ required: true, message: 'Informe o telefone!' }]}
                        >
                            <Input placeholder="Digite o Número" />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item
                            label="Cargo do Responsável"
                            name="cargo"
                            rules={[{ required: true, message: 'Escolha o cargo!' }]}
                        >
                            <Select placeholder="Escolha o Cargo">
                                <Option value="Gerente">Gerente</Option>
                                <Option value="Supervisor">Supervisor</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>

                <h2 style={{ color: "#2957A4" }}>Endereço do Site</h2>

                <Row gutter={12} align="bottom">
                    <Col span={4}>
                        <Form.Item
                            label="CEP"
                            name="cep"
                            rules={[{ required: true, message: 'Informe o CEP!' }]}
                        >
                            <Input placeholder="Digite o CEP" onChange={(e) => handleCepChange(e.target.value)} />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item
                            label="Rua"
                            name="rua"
                            rules={[{ required: true, message: 'Informe a rua!' }]}
                        >
                            <Input placeholder="Nome da Rua" />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item
                            label="Bairro"
                            name="bairro"
                            rules={[{ required: true, message: 'Informe o bairro!' }]}
                        >
                            <Input placeholder="Nome do Bairro" />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item
                            label="Cidade"
                            name="cidade"
                            rules={[{ required: true, message: 'Informe a cidade!' }]}
                        >
                            <Input placeholder="Nome da Cidade" />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item label="Complemento" name="complemento">
                            <Input placeholder="Digite o Complemento" />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item
                            label="Nº"
                            name="numero"
                            rules={[{ required: true, message: 'Informe o número!' }]}
                        >
                            <Input placeholder="Digite o Nº" onChange={(e) => handleNumeroChange(e.target.value)} />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item
                            label="Status do Site"
                            name="status"
                            
                            rules={[{ required: true, message: 'Selecione o atual status do site.' }]}
                        >
                            <Select placeholder="Selecione o status" defaultValue={"Conectado"}>
                                <Option value="Conectado">Conectado</Option>
                                <Option value="Desconectado">Desconectado</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px', gap: 8 }}>
                            {currentStep > 0 && (
                                <Button onClick={onPrev} disabled={loading}>
                                    Voltar
                                </Button>
                            )}
                            {currentStep < totalSteps - 1 && (
                                <Button
                                    // onClick={async () => {
                                    //     if (selectedSite) {
                                    //         // Apenas usa os dados do site selecionado sem salvar novamente
                                    //         const selectedSiteData = sitesExistentes.find(site => site.id === selectedSite);
                                    //         if (selectedSiteData) {
                                    //             onSave(selectedSiteData); // Passa os dados do site para o próximo passo
                                    //             onNext();
                                    //         } else {
                                    //             message.error('Site selecionado não encontrado.');
                                    //         }
                                    //     } else {
                                    //         // Salva apenas se for um novo site
                                    //         await handleSave();
                                    //         onNext();
                                    //     }
                                    // }}
                                    onClick={onNext}
                                    disabled={loading}
                                >
                                    Próximo
                                </Button>



                            )}
                            <Button type="primary" onClick={handleSave} loading={loading}>
                                Salvar
                            </Button>
                        </div>
                    </Col>
                </Row>
            </Form>
        </Layout>
    );
};

export default Site;
