import React, { createContext, useState } from 'react';
// import {jwtDecode} from 'jwt-decode';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(() => {
        const storedUser = localStorage.getItem('user');
        return storedUser ? JSON.parse(storedUser) : null;
    });

    const login = (token, username, role) => {
        const userInfo = { token, username, role };
        setUser(userInfo);
        localStorage.setItem('user', JSON.stringify(userInfo));

        localStorage.setItem('token', token);
        // localStorage.setItem('role', role);
        // localStorage.setItem('token', token);
    };

    const logout = () => {
        setUser(null);
        localStorage.removeItem('user');
    };

    return (
        <AuthContext.Provider value={{ user, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};