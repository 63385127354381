import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Layout, Space, Button, message, Select, Table, Input, Row, Col, Flex, Dropdown, Menu, Popconfirm } from 'antd';
import FooterComponent from '../components/Footer/FooterComponent';
import AppHeader from '../components/Header/AppHeader';
import SideMenu from '../components/SideMenu/SideMenu';
import { 
    Lightning,
    LightningSlash,
    Trash, 
    PencilSimpleLine
} from "@phosphor-icons/react";
import {
    EllipsisOutlined
}from "@ant-design/icons"
import { useNavigate } from 'react-router-dom';
import AneelDashboardComponent from '../components/DataAPI/AneelDashboard';
import CardsData from '../components/DataAPI/CardsData';
import GalleryCardAPIComponent from '../components/DataAPI/GalleryCardAPI';
import { useAuth } from '../hooks/useAuth';

const { Search } = Input;
const { Content } = Layout;

const url_base = process.env.REACT_APP_URL_BACKEND;

const MapPage = () => {
    const [searchText, setSearchText] = useState('');
    const [tableData, setTableData] = useState([]);
    const [filteredData, setFilteredData] = useState(tableData);
    const [responsavel, setResponsavel] = useState('');
    const [regiao, setRegiao] = useState('Todas');
    const [site, setSite] = useState('');
    const [responsaveisOptions, setResponsaveisOptions] = useState([]);
    const [sitesOptions, setSitesOptions] = useState([]);
    const [regioesOptions, setRegioesOptions] = useState([]);
    const token = localStorage.getItem('token');
    const navigate = useNavigate();

    const { user } = useAuth();
    const roles = user?.role.split(',').map(role => role.trim());
    const isAdmin =roles?.includes("ROLE_ADMIN") || roles?.includes("ROLE_DEV");

    useEffect(() => {
        fetchResponsaveis();
        fetchSites();
        fetchRegioes();
        fetchTableData();
    }, []);

    const fetchTableData = async () => {
        try {
            const response = await axios.get(`${url_base}/api/sites`, {
                headers: { Authorization: `Bearer ${token}` }
            });
            setTableData(response.data);
            setFilteredData(response.data);
        } catch (error) {
            console.error("Erro ao buscar dados da tabela:", error);
        }
    };

    const fetchResponsaveis = async () => {
        try {
            const response = await axios.get(`${url_base}/getResponsaveis`,{
                headers: { Authorization: `Bearer ${token}` }
            });

            setResponsaveisOptions(response.data);
        } catch (error) {
            console.error("Erro ao buscar responsáveis:", error);
        }
    };

    const fetchRegioes = async () => {
        try {
            const response = await axios.get(`${url_base}/getRegioes`,{
                headers: { Authorization: `Bearer ${token}` }
            });

            setRegioesOptions(response.data);
        } catch (error) {
            console.error("Erro ao buscar responsáveis:", error);
        }
    };

    const fetchSites = async () => {
        try {
            const response = await axios.get(`${url_base}/getSites`,{
                headers: { Authorization: `Bearer ${token}` }
            });

            setSitesOptions(response.data);
        } catch (error) {
            console.error("Erro ao buscar responsáveis:", error);
        }
    };

    const handleStatusChange = async (nome, currentStatus) => {
        const newStatus = currentStatus === 'Conectada' ? 'Desconectada' : 'Conectada';
    
        try {
            await axios.put(
                `${url_base}/updateSiteStatus`, 
                { nome, status: newStatus },
                { headers: { Authorization: `Bearer ${token}` } }
            );
            
            message.success(`Status do site "${nome}" atualizado para: ${newStatus}`);
            
            
            fetchTableData();
            
        } catch (error) {
            console.error("Erro ao atualizar o status:", error);
            message.error("Erro ao atualizar o status.");
        }
    };
    
    
    const getMenu = (record) => (
        <Menu>
            <Menu.Item key="delete">
                <Popconfirm
                    title="Tem certeza que deseja excluir este site?"
                    onConfirm={() => handleDelete(record.id)}
                    okText="Sim"
                    cancelText="Não"
                >
                    <div style={{ display: 'flex', alignItems: 'center', gap: '8px', cursor: 'pointer' }}>
                        <Trash />
                        <span>Excluir</span>
                    </div>
                </Popconfirm>
            </Menu.Item>
            
            <Menu.Item key="edit" disabled>
                <div style={{ display: 'flex', alignItems: 'center', gap: '8px', cursor: 'pointer' }}>
                    <PencilSimpleLine />
                    <span>Editar</span>
                </div>
            </Menu.Item>
        </Menu>

    );

    const columns = [
        {
            title: 'Nome do Agente',
            dataIndex: 'nome',
            key: 'nome',
            render: (text, record) => {
                return (
                    <a
                        style={{ cursor: 'pointer' }}
                        onClick={() =>
                            navigate('/painel-operacao', {
                                state: { 
                                    lat: record.latitude,
                                    long: record.longitude,
                                    metadata: {record}
                                }
                            })
                        }
                    >
                        {text}
                    </a>
                );
            },
        },
        {
            title: 'status',
            dataIndex: 'status',
            key: 'status',
            render: (text, record) => (
                <Button
                    type="primary"
                    style={{
                        backgroundColor: text === 'Conectada' ? '#53B487' : '#EF1D31',
                        width: '150px'
                    }}
                    onClick={() => handleStatusChange(record.site, text)}
                >
                    {text === 'Conectada' ? <Lightning /> : <LightningSlash />}
                    {text || 'Desconectada'} 
                </Button>
            ),
        },      
        { title: 'Responsável', dataIndex: 'pessoaResponsavel', key: 'pessoaResponsavel' },
        {
            title: 'UFV',
            dataIndex: 'usinaSolar',
            key: 'usinaSolar',
            render: (text, record) => (
                <Button
                    style={{
                        backgroundColor: '#F8F9F9', 
                        border: '2px solid #FFCC29', 
                        // borderRadius: '8px', 
                        color: '#000', 
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '75px',
                        height: '50px',
                        
                        
                    }}
                >
                    <span style={{ fontSize: '14px',lineHeight: '0.5',  marginBottom: 0 }}>{text} kW</span>
                    <span style={{ fontSize: '12px',lineHeight: '1',  margin: 0 }}>Outorga</span>
                </Button>
            ),
        }, 
        {
            title: 'EOL',
            dataIndex: 'usinaEolica',
            key: 'usinaEolica',
            render: (text, record) => (
                <Button
                    style={{
                        backgroundColor: '#F8F9F9', 
                        border: '2px solid #FB8500', 
                        // borderRadius: '8px', 
                        color: '#000', 
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '75px',
                        height: '50px',
                        
                        
                    }}
                >
                    <span style={{ fontSize: '14px',lineHeight: '0.5',  marginBottom: 0 }}>{text} kW</span>
                    <span style={{ fontSize: '12px',lineHeight: '1',  margin: 0 }}>Outorga</span>
                </Button>
            ),
        }, 
        // { title: 'Usina Termelétirca', dataIndex: 'usinaTermo', key: 'usinaTermo' },
        {
            title: 'UTE',
            dataIndex: 'usinaTermo',
            key: 'usinaTermo',
            render: (text, record) => (
                <Button
                    style={{
                        backgroundColor: '#F8F9F9', 
                        border: '2px solid #F3A8E2', 
                        // borderRadius: '8px', 
                        color: '#000', 
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '75px',
                        height: '50px',
                        
                        
                    }}
                >
                    <span style={{ fontSize: '14px',lineHeight: '0.5',  marginBottom: 0 }}>{text} kW</span>
                    <span style={{ fontSize: '12px',lineHeight: '1',  margin: 0 }}>Outorga</span>
                </Button>
            ),
        }, 
        {
            title: 'UTN',
            dataIndex: 'usinaTermNuc',
            key: 'usinaTermNuc',
            render: (text, record) => (
                <Button
                    style={{
                        backgroundColor: '#F8F9F9', 
                        border: '2px solid #D4B9FF', 
                        // borderRadius: '8px', 
                        color: '#000', 
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '75px',
                        height: '50px',
                        
                        
                    }}
                >
                    <span style={{ fontSize: '14px',lineHeight: '0.5',  marginBottom: 0 }}>{text} kW</span>
                    <span style={{ fontSize: '12px',lineHeight: '1',  margin: 0 }}>Outorga</span>
                </Button>
            ),
        },
        {
            title: 'UHE',
            dataIndex: 'usinaHidro',
            key: 'usinaHidro',
            render: (text, record) => (
                <Button
                    style={{
                        backgroundColor: '#F8F9F9', 
                        border: '2px solid #0000FF', 
                        // borderRadius: '8px', 
                        color: '#000', 
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '75px',
                        height: '50px',
                        
                        
                    }}
                >
                    <span style={{ fontSize: '14px',lineHeight: '0.5',  marginBottom: 0 }}>{text} kW</span>
                    <span style={{ fontSize: '12px',lineHeight: '1',  margin: 0 }}>Outorga</span>
                </Button>
            ),
        },
        {
            title: 'CGH',
            dataIndex: 'usinaCGH',
            key: 'usinaCGH',
            render: (text, record) => (
                <Button
                    style={{
                        backgroundColor: '#F8F9F9', 
                        border: '2px solid #C3E1FF', 
                        // borderRadius: '8px', 
                        color: '#000', 
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '75px',
                        height: '50px',
                        
                        
                    }}
                >
                    <span style={{ fontSize: '14px',lineHeight: '0.5',  marginBottom: 0 }}>{text} kW</span>
                    <span style={{ fontSize: '12px',lineHeight: '1',  margin: 0 }}>Outorga</span>
                </Button>
            ),
        },
        {
            title: 'PCH',
            dataIndex: 'usinaPCH',
            key: 'usinaPCH',
            render: (text, record) => (
                <Button
                    style={{
                        backgroundColor: '#F8F9F9', 
                        border: '2px solid #007BFF', 
                        // borderRadius: '8px', 
                        color: '#000', 
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '75px',
                        height: '50px',
                        
                        
                    }}
                >
                    <span style={{ fontSize: '14px',lineHeight: '0.5',  marginBottom: 0 }}>{text} kW</span>
                    <span style={{ fontSize: '12px',lineHeight: '1',  margin: 0 }}>Outorga</span>
                </Button>
            ),
        },
        {
            title: 'UBC',
            dataIndex: 'usinaUBC',
            key: 'usinaUBC',
            render: (text, record) => (
                <Button
                    style={{
                        backgroundColor: '#F8F9F9', 
                        border: '2px solid #6CD84C', 
                        // borderRadius: '8px', 
                        color: '#000', 
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '75px',
                        height: '50px',
                        
                        
                    }}
                >
                    <span style={{ fontSize: '14px',lineHeight: '0.5',  marginBottom: 0 }}>{text} kW</span>
                    <span style={{ fontSize: '12px',lineHeight: '1',  margin: 0 }}>Outorga</span>
                </Button>
            ),
        },
        // { title: 'Bateria', dataIndex: 'bateria', key: 'bateria' },
        // { title: 'Grid', dataIndex: 'grid', key: 'grid' },
        // { title: 'Carregador (Potência)', dataIndex: 'potencia', key: 'potencia' },
        { title: 'Estado', dataIndex: 'estado', key: 'estado' },
        {
            title: 'Ações',
            key: 'actions',
            render: (_, record) => (
                <Dropdown overlay={getMenu(record)} trigger={['click']}>
                    <Button type="text" icon={<EllipsisOutlined />} />
                </Dropdown>
            ),
        },
    ];

    const handleClearFilters = () => {
        setResponsavel('');
        setRegiao('Todas');
        setSite('');
    };


    const handleDelete = async (id) => {
        try {
            await axios.delete(`${url_base}/api/sites/${id}`, {
                headers: { Authorization: `Bearer ${token}` }
            });
            message.success("Site excluído com sucesso.");
            fetchTableData(); // Atualiza os dados após a exclusão
        } catch (error) {
            console.error("Erro ao excluir site:", error);
            message.error("Erro ao excluir site.");
        }
    };


    // Função para filtrar a tabela com base no texto de pesquisa
    const handleSearch = (e) => {
        const value = e.target.value.toLowerCase();
        setSearchText(value);

        const filtered = tableData.filter((item) =>
            item.site?.toLowerCase().includes(value) ||
            item.status?.toLowerCase().includes(value) ||
            item.capacidade?.toLowerCase().includes(value) ||
            item.usina?.toLowerCase().includes(value) ||
            item.bateria?.toLowerCase().includes(value) ||
            item.grid?.toLowerCase().includes(value) ||
            item.potencia?.toLowerCase().includes(value) ||
            item.estado?.toLowerCase().includes(value)
        );
        setFilteredData(filtered);
    };

    return (
        <Layout style={{ height: '100vh' }}>
            <SideMenu style={{ position: 'fixed', zIndex: 1 }} />
            <Layout>
                <AppHeader style={{ position: 'fixed', width: '100%', zIndex: 1, top: 0 }} />
                <Content
                    style={{
                        overflowY: 'auto',
                        padding: '20px',
                        display: 'flex',
                        flexDirection: 'column',
                        flex: 1,
                    }}
                >
                    <div style={{ flex: 1 }}>
                        <div style={{ marginBottom: '25px' }}>
                            <Row gutter={16} align="bottom" style={{ display: 'flex', justifyContent: 'center'}}>
                                <Col xs={24} sm={8} md={6}>
                                    <p style={{
                                        color: '#2957A4',
                                        fontFamily: 'Inter',
                                        fontSize: '16px',
                                        fontStyle: 'normal',
                                        fontWeight: '400',
                                        lineHeight: 'normal'
                                    }}>
                                        Responsável
                                    </p>
                                    <Select
                                        showSearch
                                        placeholder="Selecione o responsável"
                                        value={responsavel}
                                        onChange={(value) => setResponsavel(value)}
                                        style={{ width: '100%' }}
                                        filterOption={(input, option) =>
                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                        }
                                        options={responsaveisOptions}
                                    />
                                </Col>
                                
                                <Col xs={24} sm={8} md={6}>
                                    <p style={{
                                        color: '#2957A4',
                                        fontFamily: 'Inter',
                                        fontSize: '16px',
                                        fontStyle: 'normal',
                                        fontWeight: '400',
                                        lineHeight: 'normal'
                                    }}>
                                        Estado
                                    </p>
                                    <Select
                                        showSearch
                                        placeholder="Selecione o estado"
                                        value={regiao}
                                        onChange={(value) => setRegiao(value)}
                                        style={{ width: '100%' }}
                                        filterOption={(input, option) =>
                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                        }
                                        options={regioesOptions}
                                    />
                                </Col>
                                
                                <Col xs={24} sm={8} md={6}>
                                    <p style={{
                                        color: '#2957A4',
                                        fontFamily: 'Inter',
                                        fontSize: '16px',
                                        fontStyle: 'normal',
                                        fontWeight: '400',
                                        lineHeight: 'normal'
                                    }}>
                                        Site
                                    </p>
                                    <Select
                                        showSearch
                                        placeholder="Selecione o site"
                                        value={site}
                                        onChange={(value) => setSite(value)}
                                        style={{ width: '100%' }}
                                        filterOption={(input, option) =>
                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                        }
                                        options={sitesOptions}
                                    />
                                </Col>
                                <Col xs={24} sm={8} md={4} >
                                    <Button variant="outlined" onClick={handleClearFilters}>
                                        <p style={{marginRight:'50px'}}>
                                            Limpar Filtros
                                        </p>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="12" viewBox="0 0 15 12" fill="none">
                                            <path d="M13.2407 1.6725C13.3709 1.52922 13.4567 1.35123 13.4877 1.16013C13.5187 0.969033 13.4936 0.773045 13.4153 0.595955C13.3371 0.418865 13.2092 0.268287 13.047 0.162499C12.8849 0.0567109 12.6955 0.000260638 12.5019 0H1.50194C1.30839 0.00038135 1.11912 0.0569189 0.957077 0.162754C0.795034 0.268589 0.667186 0.419174 0.589041 0.59624C0.510896 0.773306 0.485814 0.969244 0.516836 1.16029C0.547859 1.35133 0.633653 1.52926 0.763811 1.6725L0.768811 1.67812L5.00194 6.19812V11C5.00189 11.181 5.05097 11.3586 5.14393 11.5139C5.2369 11.6692 5.37026 11.7963 5.5298 11.8818C5.68935 11.9672 5.86909 12.0078 6.04987 11.9991C6.23065 11.9904 6.40568 11.9328 6.55631 11.8325L8.55631 10.4987C8.69341 10.4074 8.80583 10.2836 8.88357 10.1384C8.96132 9.99318 9.00197 9.83098 9.00194 9.66625V6.19812L13.2351 1.67812L13.2407 1.6725ZM8.13881 5.66187C8.05223 5.75332 8.00335 5.87408 8.00194 6V9.66625L6.00194 11V6C6.00182 5.87325 5.95356 5.75128 5.86694 5.65875L1.50194 1H12.5019L8.13881 5.66187ZM14.3569 10.65C14.4508 10.7438 14.5035 10.8711 14.5035 11.0037C14.5035 11.1364 14.4508 11.2637 14.3569 11.3575C14.2631 11.4513 14.1359 11.504 14.0032 11.504C13.8705 11.504 13.7433 11.4513 13.6494 11.3575L12.5019 10.2075L11.3557 11.3544C11.2619 11.4481 11.1347 11.5007 11.002 11.5007C10.8694 11.5006 10.7422 11.4479 10.6485 11.3541C10.5548 11.2602 10.5021 11.133 10.5022 11.0004C10.5023 10.8678 10.555 10.7406 10.6488 10.6469L11.7951 9.5L10.6488 8.35312C10.559 8.25859 10.5097 8.13272 10.5113 8.00234C10.513 7.87196 10.5655 7.74739 10.6577 7.65519C10.7499 7.56298 10.8745 7.51045 11.0049 7.50878C11.1353 7.50711 11.2612 7.55644 11.3557 7.64625L12.5019 8.79313L13.6482 7.64688C13.7419 7.55306 13.8691 7.50031 14.0017 7.50026C14.1343 7.5002 14.2616 7.55283 14.3554 7.64656C14.4492 7.7403 14.5019 7.86747 14.502 8.00009C14.5021 8.13271 14.4494 8.25993 14.3557 8.35375L13.2088 9.5L14.3569 10.65Z" fill="black"/>
                                        </svg>
                                    </Button>
                                </Col>
                            </Row>
                        </div>

                        <div style={{backgroundColor:'#FFF', padding: '20px', borderRadius: '4px' }}>
                            <h1 fontFamily={'Montserrant'} color='#2F2F2F'>Painel de Sites</h1>
                            
                            
                            <CardsData/>
                            


                            <div style={{ display: 'flex', gap: '20px', marginBottom: '20px' }}>
                                <Button type="primary" style={{ backgroundColor: '#EF1D31', flex: 1 }}>
                                    Falha
                                </Button>
                                <Button type="primary" style={{ backgroundColor: '#E9A23B', flex: 1 }}>
                                    Alerta
                                </Button>
                                <Button type="primary" style={{ backgroundColor: '#53B487', flex: 1 }}>
                                    Conectada
                                </Button>
                                <Button type="primary" style={{ backgroundColor: '#2957A4', flex: 1 }}>
                                    Não Comissionada
                                </Button>
                            </div>
                            
                            <div style={{  float: 'right', marginTop: 20 }}>
                                <Search 
                                    placeholder="Pesquisar por"
                                    allowClear
                                    enterButton="Filtrar"
                                    // suffix={<SearchOutlined/>}
                                    value={searchText}
                                    onChange={handleSearch}
                                    style={{ marginBottom: '16px', width: '300px' }}
                                />

                            </div>
                            <Table
                                columns={columns}
                                dataSource={filteredData}
                                pagination={{ pageSize: 5 }}
                            />
                            <AneelDashboardComponent style={{ marginTop: 20}}/>
                            {/* <Space direction="vertical" style={{ width: "100%" }}>
                                {isAdmin && (
                                    <>
                                        

                                        <div style={{ marginBottom: "20px" }}>
                                            <GalleryCardAPIComponent />
                                        </div>

                                        
                                    </>
                                )}
                            </Space> */}
                            
                        </div>

                    </div>
                         
                    <FooterComponent />
                </Content>
            </Layout>
        </Layout>
    );
};

export default MapPage;
