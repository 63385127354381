import React from 'react';
import { Card, Typography, Flex } from 'antd';
import { RightOutlined } from '@ant-design/icons';

const { Title, Text } = Typography;

const MapButton = ({ rua = "Geolocalização", bairro = "do", cidade = "empreendimento", url = "", style, onClick }) => {
    return (
        <div 
            style={{ 
                backgroundImage: 'url("./Content/Images/map_placeholder.svg")',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                borderRadius: '12px',
                padding:'30px 38px',
                maxWidth: 496,
                minHeight: 128,
                cursor: 'pointer', 
                ...style
            }}
            onClick={onClick} 
        >
            <Card 
                style={{ 
                    borderRadius: '12px', 
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                    border: '1px solid #999',
                }}
            >
                <Flex justify="space-between" align="center">
                    <div>
                        <u><Title style={{ margin: 0, fontSize: '1.5em' }}>{rua}</Title></u>
                        <Text type="secondary" style={{ margin: 0, fontSize: '1em' }}>{bairro} - {cidade}</Text>
                    </div>
                    <div>
                        <RightOutlined style={{ margin: 0, fontSize: '24px', color: '#2957A4' }} />
                    </div> 
                </Flex>
            </Card>
        </div>
    );
};

export default MapButton;
