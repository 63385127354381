import axios from 'axios';
import { message } from 'antd';

const API_BASE_URL = process.env.REACT_APP_URL_BACKEND;
const token = localStorage.getItem('token');


export const fetchUsinasFotovoltaica = async () => {
    try {
        const response = await axios.get(`${API_BASE_URL}/api/usina-solars`, {
            headers: { Authorization: `Bearer ${token}` }
        });
        return response.data;
    } catch (error) {
        console.error("Erro ao buscar dados das usinas:", error);
        throw error;
    }
};

export const fetchUsinasEolicas = async () => {
    try {
        const response = await axios.get(`${API_BASE_URL}/api/usina-eolicas`, {
            headers: { Authorization: `Bearer ${token}` }
        });
        return response.data;
    } catch (error) {
        console.error("Erro ao buscar dados das usinas:", error);
        throw error;
    }
};

export const fetchUsinasTermeletricas = async () => {
    try {
        const response = await axios.get(`${API_BASE_URL}/api/usina-termeletricas`, {
            headers: { Authorization: `Bearer ${token}` }
        });
        return response.data;
    } catch (error) {
        console.error("Erro ao buscar dados das usinas:", error);
        throw error;
    }
};

export const fetchUsinasTermonuclear = async () => {
    try {
        const response = await axios.get(`${API_BASE_URL}/api/usina-termonuclears`, {
            headers: { Authorization: `Bearer ${token}` }
        });
        return response.data;
    } catch (error) {
        console.error("Erro ao buscar dados das usinas:", error);
        throw error;
    }
};

export const fetchUsinasHidroeletrica = async () => {
    try {
        const response = await axios.get(`${API_BASE_URL}/api/usina-hidreletricas`, {
            headers: { Authorization: `Bearer ${token}` }
        });
        return response.data;
    } catch (error) {
        console.error("Erro ao buscar dados das usinas:", error);
        throw error;
    }
};

export const fetchCentralGeradoraHidreletrica = async () => {
    try {
        const response = await axios.get(`${API_BASE_URL}/api/central-geradora-hidroeletricas`, {
            headers: { Authorization: `Bearer ${token}` }
        });
        return response.data;
    } catch (error) {
        console.error("Erro ao buscar dados das usinas:", error);
        throw error;
    }
};

export const fetchPequenaCentralGeradoraHidreletrica = async () => {
    try {
        const response = await axios.get(`${API_BASE_URL}/api/pequena-central-hidreletricas`, {
            headers: { Authorization: `Bearer ${token}` }
        });
        return response.data;
    } catch (error) {
        console.error("Erro ao buscar dados das usinas:", error);
        throw error;
    }
};

export const fetchUsinasBiocombustivel = async () => {
    try {
        const response = await axios.get(`${API_BASE_URL}/api/usina-biocombustivels`, {
            headers: { Authorization: `Bearer ${token}` }
        });
        return response.data;
    } catch (error) {
        console.error("Erro ao buscar dados das usinas:", error);
        throw error;
    }
};

export const fetchBaterias = async () => {
    try {
        const response = await axios.get(`${API_BASE_URL}/api/baterias`, {
            headers: { Authorization: `Bearer ${token}` }
        });
        return response.data;
    } catch (error) {
        console.error("Erro ao buscar dados das usinas:", error);
        throw error;
    }
};

export const fetchSitesResources = async (id) => {
    try {
        if(id){
            
        }
        const response = await axios.get(`${API_BASE_URL}/api/sites/${id}/associations`, {
            headers: { Authorization: `Bearer ${token}` }
        });
        return response.data;
    } catch (error) {
        console.error("Erro ao buscar dados das usinas:", error);
        throw error;
    }
};