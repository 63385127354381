import React, { useEffect, useState } from 'react';
import {
    Layout,
    Form,
    Input,
    Radio,
    Row,
    Col,
    message,
    Button,
    Select,
    Modal,
    Tag,
    Popconfirm,
} from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { useFormContext } from './FormProvider';

const { Option } = Select;

const UsinaSolarForm = ({
    form,
    siteData,
    currentStep,
    totalSteps,
    onPrev,
    onNext,
    handleCepChange,
}) => {
    const [loading, setLoading] = useState(false);
    const [useSameAddress, setUseSameAddress] = useState(true);
    const [modalForm] = Form.useForm();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [currentInversor, setCurrentInversor] = useState(null);
    const { formData, updateFormData } = useFormContext();
    const [inversores, setInversores] = useState([]);
    const token = localStorage.getItem('token');
    const validateSiteData = () => {
        if (!siteData || Object.keys(siteData).length === 0) {
            message.error('As informações do site são obrigatórias para salvar a Usina Solar.');
            return false;
        }
        return true;
    };

    const handleSave = async () => {
        try {

            if (!validateSiteData()) return;

            if (!inversores || inversores.length === 0) {
                message.error('Adicione pelo menos um inversor antes de salvar.');
                return;
            }
            await form.validateFields();
            setLoading(true);
            const values = form.getFieldsValue();
            const payload = {
                bairro: values.bairroUsina,
                cep: values.cepUsina,
                cidade: values.cidadeUsina,
                emailResponsavel: values.emailGerente,
                estado: values.estadoUsina || siteData.estado,
                inversor: inversores[0], 
                kilowattPico: parseFloat(values.kwpTotal) || 0,
                latitude: parseFloat(values.latitude) || 0,
                longitude: parseFloat(values.longitude) || 0,
                logradouro: values.ruaUsina,
                mesmoEnderecoSite: useSameAddress,
                nome: values.nomeGerente,
                numero: parseInt(values.numeroUsina, 10) || 0,
                numeroModulos: parseInt(values.modulosUsina, 10) || 0,
                pessoaResponsavel: values.nomeGerente,
                potenciaInversor: parseFloat(values.potenciaInversor) || 0,
                potenciaModulo: parseFloat(values.potenciaModuloUsina) || 0,
                protocoloComunicacao: values.protocoloComunicacao || 'Não informado',
                site: siteData, // Informações do site
                tipoInversor: values.tipoInversor || 'Não informado',
                uuid: uuidv4(),
            };

            await axios.post(`${process.env.REACT_APP_URL_BACKEND}/api/usina-solars`, payload, {
                headers: { Authorization: `Bearer ${token}` },
            });

            message.success('Usina Solar salva com sucesso!');
        } catch (error) {
            message.error(error.message || 'Erro ao salvar a Usina Solar.');
        } finally {
            setLoading(false);
        }
    };

    const showModal = (inversor = null) => {
        setCurrentInversor(inversor);
        if (inversor) {
            modalForm.setFieldsValue(inversor);
        } else {
            modalForm.resetFields();
        }
        setIsModalVisible(true);
    };
    

    const handleCloseModal = () => {
        setIsModalVisible(false);
        setCurrentInversor(null);
        modalForm.resetFields();
    };

    
    
    const handleSaveInversor = async () => {
        try {
            // Valida os campos do modal
            const values = await modalForm.validateFields();
            
            // Monta o payload do inversor
            let inversorPayload = {
                uuid: currentInversor?.uuid || uuidv4(), // Novo UUID se for um novo inversor
                nome: values.nome,
                fabricante: values.fabricante || 'Não informado',
                modelo: values.modelo,
                tipoEquipamento: values.tipoEquipamento || 'Não informado',
                versaoProtocolo: values.versaoProtocolo || 'Não informado',
                versaoSoftware: values.versaoSoftware || 'Não informado',
                numeroSerie: values.numeroSerie,
                numeroSN: values.numeroSN || '',
                comissionado: values.comissionado || '',
                senha: values.senha || '',
                porta: parseInt(values.porta, 10) || 0,
                enderecoIP: values.enderecoIP || '',
                tipoCanal: values.tipoCanal || '',
                codigoPadraoRede: values.codigoPadraoRede || '',
                nivelTensao: parseFloat(values.nivelTensao) || 0,
                nivelFrequencia: parseFloat(values.nivelFrequencia) || 0,
            };
    
            // Salva no backend
            const response = await axios.post(`${process.env.REACT_APP_URL_BACKEND}/api/inversors`, inversorPayload, {
                headers: { Authorization: `Bearer ${token}` },
            });
    
            // Adiciona o ID retornado pelo backend ao payload
            inversorPayload = { ...inversorPayload, id: response.data.id };
    
            // Atualiza a lista de inversores
            const updatedInversores = currentInversor
                ? inversores.map((inv) => (inv.uuid === currentInversor.uuid ? inversorPayload : inv))
                : [...inversores, inversorPayload];
    
            setInversores(updatedInversores); // Atualiza o estado local com os inversores
            message.success(currentInversor ? 'Inversor atualizado!' : 'Inversor adicionado!');
            handleCloseModal();
        } catch (error) {
            console.error('Erro ao salvar o inversor:', error);
            const errorMessage = error.response?.data?.message || 'Erro ao salvar o inversor. Verifique os dados e tente novamente.';
            message.error(errorMessage);
        }
    };
    
    
    
    

    const handleDeleteInversor = (id) => {
        const updatedInversores = inversores.filter((inv) => inv.numeroSerie !== id);
        setInversores(updatedInversores);
        message.success('Inversor removido.');
    };

    useEffect(() => {
        if (useSameAddress && siteData) {
            form.setFieldsValue({
                cepUsina: siteData.cep || '',
                ruaUsina: siteData.logradouro || '',
                bairroUsina: siteData.bairro || '',
                cidadeUsina: siteData.cidade || '',
                numeroUsina: siteData?.numero || ' ',
            });
        }
    }, [useSameAddress, siteData]);
    

    const handleSameAddressChange = (e) => {
        const sameAddress = e.target.value === true;
        setUseSameAddress(sameAddress);

        if (sameAddress && siteData) {
            form.setFieldsValue({
                cepUsina: siteData.cep || '',
                ruaUsina: siteData.logradouro || '',
                bairroUsina: siteData.bairro || '',
                cidadeUsina: siteData.cidade || '',
                numeroUsina: siteData.numero || '',
            });
        }else{
            form.setFieldsValue({
                cepUsina: '',
                ruaUsina: '',
                bairroUsina: '',
                cidadeUsina: '',
                numeroUsina: '',
            });
        }
    };

    return (
        <Layout style={{ padding: '20px', backgroundColor: 'white' }}>
            <Form layout="vertical" form={form} initialValues={{ inversores: [] }}>
                <h2 style={{color: '#2957A4'}}>Usina Solar</h2>

                <Row gutter={12} align="bottom">
                    <Col span={4}>
                        <Form.Item
                            label="Nome do Gerente"
                            name="nomeGerente"
                            rules={[{ required: true, message: 'Informe o nome do gerente!' }]}
                        >
                            <Input placeholder="Nome do Gerente" />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item
                            label="Email do Gerente"
                            name="emailGerente"
                            rules={[{ required: true, message: 'Informe o email!' }]}
                        >
                            <Input placeholder="Digite o Email" />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item
                            label="Telefone ou Celular"
                            name="telefoneGerente"
                            rules={[{ required: true, message: 'Informe o telefone!' }]}
                        >
                            <Input placeholder="Digite o Número" />
                        </Form.Item>
                    </Col>
                </Row>
                <h2 style={{color: '#2957A4'}}>Endereço da Usina</h2>
                <Row gutter={12} align="bottom">
                    <Col span={24}>
                        <Form.Item label="O Endereço é o mesmo do site?" name="sameAddress">
                            <Radio.Group onChange={handleSameAddressChange} defaultValue={true}>
                                <Radio value={true}>Sim</Radio>
                                <Radio value={false}>Não</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                </Row>

                
                <Row gutter={12} align="bottom">
                    <Col span={4}>
                        <Form.Item
                            label="CEP"
                            name="cepUsina"
                            rules={[{ required: true, message: 'Informe o CEP!' }]}
                        >
                            <Input
                                placeholder="Digite o CEP"
                                onChange={(e) => handleCepChange(e.target.value)}
                                disabled={useSameAddress}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item
                            label="Rua"
                            name="ruaUsina"
                            rules={[{ required: true, message: 'Informe a rua!' }]}
                        >
                            <Input placeholder="Nome da Rua" disabled={useSameAddress} />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item
                            label="Bairro"
                            name="bairroUsina"
                            rules={[{ required: true, message: 'Informe o bairro!' }]}
                        >
                            <Input placeholder="Nome do Bairro" disabled={useSameAddress} />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item
                            label="Cidade"
                            name="cidadeUsina"
                            rules={[{ required: true, message: 'Informe a cidade!' }]}
                        >
                            <Input placeholder="Nome da Cidade" disabled={useSameAddress} />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item
                            label="Nº"
                            name="numeroUsina"
                            rules={[{ required: true, message: 'Informe o número!' }]}
                        >
                            <Input placeholder="Digite o Nº" disabled={useSameAddress} />
                        </Form.Item>
                    </Col>
                </Row>

                <h2 style={{color: '#2957A4'}}>Informações Gerais</h2>
                <Row gutter={12} align="bottom">
                    <Col span={4}>
                        <Form.Item
                            label="kWp Total"
                            name="kwpTotal"
                            rules={[{ required: true, message: 'Informe a potência total!' }]}
                        >
                            <Input placeholder="kWp Total"/>
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item
                            label="Potência Outorgada"
                            name="potenciaOutorga"
                            rules={[{ required: true, message: 'Informe a potência de !' }]}
                        >
                            <Input placeholder="Potência Outorgada" />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item
                            label="Quantidade de Módulos"
                            name="modulos"
                            rules={[{ required: true, message: 'Informe a quantidade de módulos!' }]}
                        >
                            <Input placeholder="Quantidade de módulos" />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item
                            label="Potência do Módulo (W)"
                            name="potenciaModulo"
                            rules={[{ required: true, message: 'Informe a potência do módulo!' }]}
                        >
                            <Input placeholder="Potência do Módulo" />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item
                            label="Protocolo de Comunicação"
                            name="protocoloComunicacaoModulo"
                            rules={[{ required: true, message: 'Informe o protocolo de comunicação do módulo!' }]}
                        >
                            <Input placeholder="Protocolo de Comunicação" />
                        </Form.Item>
                    </Col>
                </Row>

                <Button 
                    type="primary" 
                    onClick={() => showModal()}
                    style={{width:'205px'}}
                >
                    + Adicionar Inversor
                </Button>
                
                
                <div style={{ display: 'flex', flexWrap: 'wrap', gap: '8px', marginTop: '16px' }}>
                {inversores.map((inversor) => (
                        <Tag
                            key={inversor.id}
                            color="orange"
                            onClick={() => showModal(inversor)}
                            style={{
                                cursor: 'pointer',
                                width: '205px',
                                height: '30px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                fontWeight: '800',
                                fontSize: '16px',
                                textAlign: 'center',
                                position: 'relative',
                            }}
                        >
                            Inversor Nº {inversor.numeroSerie}
                            <Popconfirm
                                title="Tem certeza que deseja deletar este inversor?"
                                onConfirm={() => handleDeleteInversor(inversor.numeroSerie)}
                                okText="Sim"
                                cancelText="Não"
                            >
                                <DeleteOutlined
                                    style={{
                                        position: 'absolute',
                                        right: '5px',
                                        top: '5px',
                                        fontSize: '14px',
                                        cursor: 'pointer',
                                    }}
                                    onClick={(e) => e.stopPropagation()}
                                />
                            </Popconfirm>
                        </Tag>
                    ))}
                </div>


                <Modal
                    title={currentInversor ? 'Editar Inversor' : 'Adicionar Inversor'}
                    open={isModalVisible}
                    onCancel={handleCloseModal}
                    onOk={handleSaveInversor}
                    width={'50%'}
                >
                    <Form layout="vertical" form={modalForm} name="inversores">
                        <Row gutter={12} align="bottom">
                            <Col span={12}>
                                <Form.Item
                                    label="Nome do Dispositivo"
                                    name="nome"
                                    rules={[{ required: true, message: 'Informe o nome do dispositivo!' }]}
                                >
                                    <Input placeholder="Nome do Dispositivo" />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="Fabricante"
                                    name="fabricante"
                                    rules={[{ required: true, message: 'Informe o fabricante!' }]}
                                >
                                    <Input placeholder="Fabricante" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={12} align="bottom">
                            <Col span={12}>
                                <Form.Item
                                    label="Modelo"
                                    name="modelo"
                                    rules={[{ required: true, message: 'Informe o modelo!' }]}
                                >
                                    <Input placeholder="Modelo" />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="Tipo de Equipamento"
                                    name="tipoEquipamento"
                                    rules={[{ required: true, message: 'Informe o tipo de equipamento!' }]}
                                >
                                    <Input placeholder="Tipo de Equipamento" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={12} align="bottom">
                            <Col span={12}>
                                <Form.Item
                                    label="Versão do Protocolo"
                                    name="versaoProtocolo"
                                >
                                    <Input placeholder="Versão do Protocolo" />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="Versão do Software"
                                    name="versaoSoftware"
                                >
                                    <Input placeholder="Versão do Software" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={12} align="bottom">
                            <Col span={12}>
                                <Form.Item
                                    label="Número de Série"
                                    name="numeroSerie"
                                    rules={[{ required: true, message: 'Informe o número de série!' }]}
                                >
                                    <Input placeholder="Número de Série" />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="Número SN"
                                    name="numeroSN"
                                >
                                    <Input placeholder="Número SN" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={12} align="bottom">
                            <Col span={12}>
                                <Form.Item
                                    label="Comissionado"
                                    name="comissionado"
                                >
                                    <Input placeholder="Comissionado" />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="Senha"
                                    name="senha"
                                >
                                    <Input.Password placeholder="Senha" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={12} align="bottom">
                            <Col span={12}>
                                <Form.Item
                                    label="Porta"
                                    name="porta"
                                    rules={[{ required: true, message: 'Informe a porta!' }]}
                                >
                                    <Input type="number" placeholder="Porta" />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="Endereço IP"
                                    name="enderecoIP"
                                >
                                    <Input placeholder="Endereço IP" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={12} align="bottom">
                            <Col span={12}>
                                <Form.Item
                                    label="Tipo de Canal"
                                    name="tipoCanal"
                                >
                                    <Input placeholder="Tipo de Canal" />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="Código Padrão da Rede"
                                    name="codigoPadraoRede"
                                >
                                    <Select placeholder="Escolha o Código">
                                        <Option value="1">Código 1</Option>
                                        <Option value="2">Código 2</Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={12} align="bottom">
                            <Col span={12}>
                                <Form.Item
                                    label="Nível de Tensão (V)"
                                    name="nivelTensao"
                                    rules={[{ required: true, message: 'Informe o nível de tensão!' }]}
                                >
                                    <Input type="number" placeholder="Digite o Nível de Tensão (V)" />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="Nível de Frequência (Hz)"
                                    name="nivelFrequencia"
                                    rules={[{ required: true, message: 'Informe o nível de frequência!' }]}
                                >
                                    <Input type="number" placeholder="Digite a Frequência (Hz)" />
                                </Form.Item>
                            </Col>
                        </Row>
                        
                    </Form>
                </Modal>



                
                {/* Botões de controle */}
                <Row>
                    <Col>
                        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px', gap: 8 }}>
                            {currentStep > 0 && (
                                <Button onClick={onPrev} disabled={loading}>
                                    Voltar
                                </Button>
                            )}
                            {currentStep < totalSteps - 1 && (
                                <Button onClick={onNext} disabled={loading}>
                                    Próximo
                                </Button>
                            )}
                            <Button type="primary" onClick={handleSave} loading={loading}>
                                Salvar
                            </Button>
                        </div>
                    </Col>
                </Row>
            </Form>
        </Layout>
    );
};

export default UsinaSolarForm;
