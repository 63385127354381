import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Typography, Layout, Row, Col } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { InfoCircleOutlined } from '@ant-design/icons';

const url_base = process.env.REACT_APP_URL_BACKEND;

const { Title, Text } = Typography;
const { Content } = Layout;

const images = [
    '/Content/Images/back_4.jpeg',
    '/Content/Images/back_5.jpeg',
    '/Content/Images/back_6.jpeg',
    '/Content/Images/back_1.jpeg',
    '/Content/Images/back_2.jpeg',
    '/Content/Images/back_3.jpeg',
];

const Register = () => {
    const navigate = useNavigate();
    const [currentImage, setCurrentImage] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentImage((prevImage) => (prevImage + 1) % images.length);
        }, 5000);

        return () => clearInterval(interval); 
    }, []);

    const handleRegister = async (values) => {
        const { firstName, lastName, email, password } = values;
        try {
            const requestBody = {
                id: 0,
                login: email,
                firstName,
                lastName,
                email,
                imageUrl: "",
                activated: true,
                langKey: "pt-br",
                createdBy: email,
                createdDate: new Date().toISOString(),
                lastModifiedBy: email,
                lastModifiedDate: new Date().toISOString(),
                authorities: ["USER"],
                password,
            };

        
            // await axios.post(`${url_base}/api/register`, requestBody, {
            await axios.post(`${url_base}/api/register`, requestBody, {
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json'
                },
                // withCredentials: true 
            });
            alert('Usuário registrado com sucesso!');
            navigate('/login');
        } catch (error) {
            alert('Erro no registro do usuário.');
            console.error(error.response ? error.response.data : error.message);
        }
    };
    
    
    

    return (
        <Layout style={{ minHeight: '100vh', flexDirection: 'row' }}>
            <div
                style={{
                    flex: 2,
                    position: 'relative',
                    overflow: 'hidden',
                }}
            >
                {images.map((image, index) => (
                    <div
                        key={index}
                        style={{
                            backgroundImage: `url(${image})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            opacity: currentImage === index ? 1 : 0,
                            transition: 'opacity 1s ease',
                            zIndex: currentImage === index ? 1 : 0,
                        }}
                    />
                ))}
            </div>

            <Content style={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '10px' }}>
                <div style={{ width: '100%', maxWidth: '400px' }}>
                    <img 
                        src="./Content/Images/logo_color.png" 
                        alt="Logo Heliosfera Energias" 
                        style={{ marginBottom: '20px', width: '60%', display: 'block', marginLeft: 'auto', marginRight: 'auto' }}
                    />
                    <Title level={2} style={{ textAlign: 'center' }}>Inscrever-se</Title>
                    <Text type="secondary" style={{ display: 'block', textAlign: 'center', marginBottom: '20px', fontSize: '18px' }}>
                        Insira os dados para criar sua conta
                    </Text>

                    <Form layout="vertical" name="register-form" onFinish={handleRegister}>
                        <Form.Item
                            label="Primeiro Nome"
                            name="firstName"
                            rules={[{ required: true, message: 'Por favor, digite seu primeiro nome!' }]}
                        >
                            <Input placeholder="Digite seu primeiro nome" style={{ height: '45px' }} />
                        </Form.Item>

                        <Form.Item
                            label="Último Nome"
                            name="lastName"
                            rules={[{ required: true, message: 'Por favor, digite seu último nome!' }]}
                        >
                            <Input placeholder="Digite seu último nome" style={{ height: '45px' }} />
                        </Form.Item>

                        <Form.Item
                            label="Email"
                            name="email"
                            rules={[
                                { required: true, message: 'Por favor, digite seu email!' },
                                { type: 'email', message: 'Digite um email válido!' },
                            ]}
                        >
                            <Input placeholder="Digite seu email" style={{ height: '45px' }} />
                        </Form.Item>

                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    label="Senha"
                                    name="password"
                                    rules={[
                                        { required: true, message: 'Por favor, digite sua senha!' },
                                        { min: 8, message: 'A senha deve ter pelo menos 8 caracteres.' },
                                        {
                                            pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$/,
                                            message: 'A senha deve conter pelo menos uma letra maiúscula, uma letra minúscula, um número e um caractere especial.',
                                        }
                                    ]}
                                    tooltip={{ title: 'A senha deve conter pelo menos uma letra maiúscula, uma letra minúscula, um número e um caractere especial.', icon: <InfoCircleOutlined /> }}
                                >
                                    <Input.Password placeholder="Digite sua senha" style={{ height: '45px' }} />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="Confirme sua senha"
                                    name="confirmPassword"
                                    dependencies={['password']}
                                    rules={[
                                        { required: true, message: 'Por favor, confirme sua senha!' },
                                        { min: 8, message: 'A senha deve ter pelo menos 8 caracteres.' },
                                        ({ getFieldValue }) => ({
                                            validator(_, value) {
                                                if (!value || getFieldValue('password') === value) {
                                                    return Promise.resolve();
                                                }
                                                return Promise.reject(new Error('As senhas não correspondem!'));
                                            },
                                        }),
                                    ]}
                                >
                                    <Input.Password placeholder="Confirme sua senha" style={{ height: '45px' }} />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Form.Item>
                            <Button 
                                type="primary" 
                                htmlType="submit" 
                                block 
                                style={{ height: '45px', backgroundColor: '#FB8500' }}
                            >
                                Inscrever-se
                            </Button>
                        </Form.Item>

                        <Form.Item>
                            <Text type="secondary" style={{ display: 'block', textAlign: 'center' }}>
                                Já tem uma conta? <Link to="/login">Entrar</Link>
                            </Text>
                        </Form.Item>
                    </Form>
                </div>
            </Content>
        </Layout>
    );
};

export default Register;