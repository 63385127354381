import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Typography, Layout, Modal } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';
import axios from 'axios';

const { Title, Text } = Typography;
const { Content } = Layout;
const url_base = process.env.REACT_APP_URL_BACKEND;


const images = [
    '/Content/Images/back_4.jpeg',
    '/Content/Images/back_2.jpeg',
    '/Content/Images/back_6.jpeg',
    '/Content/Images/back_1.jpeg',
    '/Content/Images/back_3.jpeg',
    '/Content/Images/back_5.jpeg',
];

const Login = () => {
    const [loading, setLoading] = useState(false);
    const { login } = useAuth();
    const navigate = useNavigate();
    const [currentImage, setCurrentImage] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentImage((prevImage) => (prevImage + 1) % images.length);
        }, 4000);

        return () => clearInterval(interval); 
    }, []);

    const showModal = (title, content) => {
        Modal.error({
            title,
            content,
        });
    };

    const handleLogin = async (values) => {
        const { username, password } = values;
        setLoading(true);
        try {
            const rememberMe = true;
            const response = await axios.post(`${url_base}/api/authenticate`, { username, password, rememberMe }, {
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json'
                },
            });
    
            if (response.status === 200 && response.data.id_token) {
                // Extrai as informações adicionais do backend
                const { id_token, role, username } = response.data;
    
                // Realiza o login e armazena o token, role e username
                login(id_token, username, role);
                navigate('/mapa-dos-sites');

            } else {
                showModal('Falha no login', 'Resposta inesperada do servidor.');
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                showModal('Credenciais inválidas', 'Por favor, verifique suas credenciais e tente novamente.');
            } else {
                console.error('Erro inesperado:', error);
                showModal('Falha no login', 'Verifique sua conexão ou tente novamente mais tarde.');
            }
        } finally {
            setLoading(false);
        }
    };
    
    
    

    return (
        <Layout style={{ minHeight: '100vh', flexDirection: 'row' }}>
            <div
                style={{
                    flex: 2,
                    position: 'relative',
                    overflow: 'hidden',
                }}
            >
                {images.map((image, index) => (
                    <div
                        key={index}
                        style={{
                            backgroundImage: `url(${image})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            opacity: currentImage === index ? 1 : 0,
                            transition: 'opacity 1s ease',
                            zIndex: currentImage === index ? 1 : 0,
                        }}
                    />
                ))}
            </div>

            <Content
                style={{
                    flex: 1,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: '10px',
                }}
            >
                <div style={{ width: '100%', maxWidth: '400px' }}>
                    <img 
                        src="./Content/Images/logo_color.png" 
                        alt="Logo Heliosfera Energias" 
                        style={{ marginBottom: '20px', width: '60%', display: 'block', marginLeft: 'auto', marginRight: 'auto' }}
                    />
                    <Title level={2} style={{ textAlign: 'center' }}>Faça login na conta</Title>
                    <Text type="secondary" style={{ display: 'block', textAlign: 'center', marginBottom: '20px', fontSize:'18px' }}>
                        Digite seu e-mail e senha para continuar
                    </Text>

                    <Form
                        layout="vertical"
                        name="login-form"
                        onFinish={handleLogin}
                    >
                        <Form.Item
                            label="Email"
                            name="username"
                            rules={[{ required: true, message: 'Por favor, digite seu e-mail!' }]}
                        >
                            <Input placeholder="Digite seu Email" style={{height:'45px'}} />
                        </Form.Item>

                        <Form.Item
                            label="Senha"
                            name="password"
                            rules={[{ required: true, message: 'Por favor, digite sua senha!' }]}
                        >
                            <Input.Password placeholder="Digite sua Senha" style={{height:'45px'}} />
                        </Form.Item>

                        <Form.Item>
                            <Button 
                                type="primary" 
                                htmlType="submit" 
                                block 
                                style={{height:'45px', backgroundColor:'#FB8500'}} 
                                loading={loading} 
                            >
                                Entrar
                            </Button>
                        </Form.Item>

                        <Form.Item>
                            <Link to="/forgot-password" style={{ float: 'right' }}>
                                Esqueceu a senha?
                            </Link>
                        </Form.Item>

                        <Form.Item>
                            <Text type="secondary" style={{ display: 'block', textAlign: 'center' }}>
                                Não tem uma conta? <Link to="/register">Cadastrar</Link>
                            </Text>
                        </Form.Item>
                    </Form>
                </div>
            </Content>
        </Layout>
    );
};

export default Login;