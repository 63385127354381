import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';
import Login from '../Logins/Login';
import Register from '../Logins/Register'
import ForgotPassword from '../Logins/ForgotPassword';
import ProtectedRoute from '../ProtectedRoute';
import MapPage from '../../Pages/MapPage'; 
import CadastrosPage from '../../Pages/CadastrosPage'; 
import OperacaoPage from '../../Pages/OperacaoPage'; 
import DashboardMeteorologico from '../../Pages/DashboardMeteorologico'; 
import AnalisesPage from '../../Pages/AnalisesPage'; 
import ConfiguracoesPage from '../../Pages/ConfiguracoesPage';
import UserManagementPage from '../../Pages/UserManagementPage';
import AccountManagementPage from '../../Pages/AccountManagementPage';

const AppRoutes = () => {
    const { user } = useAuth();
    const roles = user?.role.split(',').map(role => role.trim());
    const isAdmin = roles?.includes("ROLE_ADMIN") || roles?.includes("ROLE_DEV");

    return (
        <Routes>
            {/* Rota inicial */}
            <Route path="/" element={<Navigate to={user ? '/mapa-dos-sites' : '/login'} replace />} />

            {/* Rotas de autenticação */}
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route 
                path="/gerenciar-usuarios" 
                element={
                    <ProtectedRoute>
                         {isAdmin ? <UserManagementPage /> : <Navigate to="/nao-autorizado" />}
                    </ProtectedRoute>

            } />
            <Route path="/gerenciar-conta" element={<AccountManagementPage />} />

            {/* Rotas protegidas - só acessíveis quando autenticado */}
            <Route
                path="/mapa-dos-sites"
                element={
                    <ProtectedRoute>
                        <MapPage />
                    </ProtectedRoute>
                }
            />
            <Route
                path="/cadastros"
                element={
                    <ProtectedRoute>
                        <CadastrosPage />
                    </ProtectedRoute>
                }
            />
            <Route
                path="/painel-operacao"
                element={
                    <ProtectedRoute>
                        <OperacaoPage />
                    </ProtectedRoute>
                }
            />
            <Route
                path="/Dados-Meteorologicos"
                element={
                    <ProtectedRoute>
                        <DashboardMeteorologico />
                    </ProtectedRoute>
                }
            />
            <Route
                path="/analises"
                element={
                    <ProtectedRoute>
                        <AnalisesPage />
                    </ProtectedRoute>
                }
            />
            <Route
                path="/configuracoes"
                element={
                    <ProtectedRoute>
                        <ConfiguracoesPage />
                    </ProtectedRoute>
                }
            />

            {/* Rota de fallback para páginas não encontradas */}
            <Route path="*" element={<Navigate to={user ? '/mapa-dos-sites' : '/login'} replace />} />
        </Routes>
    );
};

export default AppRoutes;
